export default [
  {
    path: `/`,
    name: 'home',
    public: true,
    component: () => import('../pages/index.vue')
  },
  {
    path: '/login',
    name: 'signin',
    public: true,
    component: () => import('../pages/signin/index.vue')
  },
  {
    path: '/login-conta',
    name: 'auth',
    public: true,
    component: () => import('../pages/signin/authentication.vue')
  },
  {
    path: '/criar-conta',
    name: 'signup',
    public: true,
    component: () => import('../pages/signup/index.vue')
  },
  {
    path: '/esqueci-minha-senha',
    name: 'forgot-password',
    public: true,
    component: () => import('../pages/forgot-password/index.vue')
  },
  {
    path: '/minha-proposta/:id?',
    name: 'my-proposals',
    public: true,
    component: () => import('../pages/proposals/index.vue')
  }
]

export default [
  {
    path: '/dashboard',
    name: 'dashboard-bff',
    meta: { layout: 'bff' },
    component: () => import('../pages/dasboard/index.vue')
  },
  {
    path: '/parceiro',
    name: 'dashboard-affiliate',
    meta: { layout: 'bff' },
    component: () => import('../pages/dasboard-affiliate/index.vue')
  },
  {
    path: '/parceiro/proposta/:id?',
    name: 'bff-proposals',
    meta: { layout: 'empty' },
    component: () => import('../pages/proposals/index.vue')
  }
]

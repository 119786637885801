const userType = localStorage.getItem('x-app-token-type')
// 0 = member
// 2 = affiliate
export let menuDataBFF = [
  {
    headTitle: 'dashboard'
  },
  {
    path: userType == 0 ? `/dashboard` : `/parceiro`,
    title: 'Dashboard',
    type: 'link',
    icon: 'ti-home',
    active: true,
    defaultActive: false
  }
]

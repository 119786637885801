import { createApp } from 'vue'

import './style.scss'
import 'swiper/swiper-bundle.css'

import router from './router/index.js'
import App from './App.vue'

import 'bootstrap/dist/js/bootstrap.bundle.js'
import { createPinia } from 'pinia'

// multiselect
import 'vue-multiselect/dist/vue-multiselect.css'

// toasts
const app = createApp(App)
app.use(router)

const pinia = createPinia()
app.use(pinia)

import VueMask from '@devindex/vue-mask'
app.use(VueMask)

import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

app.config.globalProperties.$toast = toast
app.mount('#app')

export let menuDataBO = [
  {
    headTitle: 'dashboard'
  },
  {
    path: `/bo/dashboard`,
    title: 'Dashboard',
    type: 'link',
    icon: 'ti-home',
    active: true,
    defaultActive: false
  },
  {
    title: 'Cadastros',
    icon: 'ti-shield',
    type: 'sub',
    active: false,
    defaultActive: false,
    children: [
      {
        path: `/admin/customers`,
        title: 'Clientes',
        type: 'link',
        active: false,
        defaultActive: false
      },
      {
        path: `/admin/categories`,
        title: 'Categorias',
        type: 'link',
        active: false,
        defaultActive: false
      },
      {
        path: `/admin/products`,
        title: 'Produtos',
        type: 'link',
        active: false,
        defaultActive: false
      },
      {
        path: `/admin/plataform-terms`,
        title: 'Termos de uso',
        type: 'link',
        active: false,
        defaultActive: false
      }
      // {
      //   path: `/admin/users`,
      //   title: 'Usuários',
      //   type: 'link',
      //   active: false,
      //   defaultActive: false
      // }
      // {
      //   path: `/admin/categories`,
      //   title: 'Categorias',
      //   type: 'link',
      //   active: false,
      //   defaultActive: false
      // },

      // {
      //   path: `/admin/customers`,
      //   title: 'Clientes',
      //   type: 'link',
      //   active: false,
      //   defaultActive: false
      // }
      // {
      //   path: `/admin/coupons`,
      //   title: 'Cupons',
      //   type: 'link',
      //   active: false,
      //   defaultActive: false
      // }
    ]
  }
]
